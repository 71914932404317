//import Vue from "vue";

//var MockAdapter = require("axios-mock-adapter");

const MockService = {
  init() {
    // this sets the mock adapter on the default instance
  }
};

export default MockService;
