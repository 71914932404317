import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@/core/plugins/treeselect";
import "@mdi/font/css/materialdesignicons.css";

import Vuex from "vuex";
// import Vueditor from "vueditor";

// import "vueditor/dist/style/vueditor.min.css";
// import config from "@/toolbar";

import Vuelidate from "vuelidate";

import VueGtag from "vue-gtag";

// CKEditor
import CKEditor from 'ckeditor4-vue';
Vue.use(CKEditor);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_ANALYTICS_PROPERTY_ID }
});

Vue.use(Vuex);
Vue.use(Vuelidate);

Vue.filter("currency", function(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter("amount", function(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("es-ES", {
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});
// API service init
ApiService.init();

// Remove this to disable mock API
MockService.init();

// Ensure we checked auth every time interval
setInterval(() => {
  store.dispatch(VERIFY_AUTH);
}, 15000);

router.beforeEach(async (to, from, next) => {
  // Ensure we checked auth before each page load.
  await Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  try{

    let { data } = await ApiService.get(`admins`, "me");
    let currentUser = data;

    //Redirecciones dependiendo de los permisos de los usuarios
    console.log(to.meta, to.meta.redirectsByPermissions)
    if(to.meta.redirectsByPermissions){
      for(let i = 0; i < to.meta.redirectsByPermissions.length; i++){
        let child = to.meta.redirectsByPermissions[i]
        console.log("trying to redirect",child);
        if(currentUser.permissions.some(permission => child.permissions.includes(permission))){
          window.location.href = `/#${child.to}`;
          return;
        }
      }
    }

    if(to.meta && to.meta.permissions){
      if(!currentUser.permissions.some(permission => to.meta.permissions.includes(permission))){
        window.location.href = "/#/403";
      }
    }
  } catch(e){
    console.log(e)
  }
  // console.log(to)

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
