// Spain
export const locale = {
  TRANSLATOR: {
    SELECT: "Elige tu idioma"
  },
  MENU: {
    NEW: "nuevo",
    ACTIONS: "Comportamiento",
    CREATE_POST: "Crear nueva publicación",
    PAGES: "Pages",
    FEATURES: "Caracteristicas",
    APPS: "Aplicaciones",
    DASHBOARD: "Tablero",
    NOTIFICATIONS: "Notificaciones",
    VALIDATIONS: "Validaciones",

    SERVICES: "Servicios",
    APPLICATIONS: "Aplicaciones",
    MY_ACCOUNT: "Mi Cuenta",
    MY_PROFILE: "Mi Perfil",
    SIGNOUT: "Cerrar Sesión"
  },
  LAYOUT: {
    CREDITS: "Créditos",
    ABOUT: "Acerca de nosotros",
    TEAM: "Equipo",
    CONTACT: "Contacto"
  },
  TABLE: {
    CREDITS: "Créditos",
    SERVICE: "Servicio",
    METHOD: "Método",
    APP: "Applicación",
    NAME: "Nombre",
    EMAIL: "Email",
    COST: "Costo",
    SPEND: "Gastado",
    MOUNT: "Monto",
    BILL: "Factura",
    DESC: "Descripción",
    STATUS: "Estado",
    CREATED: "Fecha de creación",
    DATE: "Fecha",
    ELEMENTS: "Elementos por página "
  },
  STATUS: {
    ACTIVE: "Activo",
    INACTIVE: "Inactivo",
    PRIVATE: "Privado",
    PUBLIC: "Publico",
    HIDDEN: "Oculto"
  },
  ACTION: {
    ENABLE: "Activar",
    UNABLE: "Desactivar",
    EDIT: "Editar",
    UPDATE: "Actualizar",
    CHANGE: "Cambiar",
    CREATE: "Crear",
    DELETE: "Eliminar",
    CANCEL: "Cancelar",
    GENERATE: "Regenerar",
    DETAILS: "Ver detalles",
    PASSWORD_UPDATE: "Cambio de contraseña",
    BACK: "Volver",
    ADD: "Agregar"
  },
  HISTORY: {
    REQ: "Historial de peticiones",
    BILL: "Historial de facturas",
    CREDIT: "Historial de recargas"
  },
  ACCOUNT: {
    TITLE: "Cuenta",
    TITLE_MY: "Mi Cuenta",
    PROFILE: "Perfil",
    PERSONAL: "Información Personal",
    UPDATE_PROFILE: "Actualiza tu infromación personal",
    GENERAL: "Información General",
    CUR_PASS: "Contraseña actual",
    CHANGE_PASS: "Cambia tu contraseña",
    NEW_PASS: "Nueva contraseña",
    REPEAT_PASS: "Repetir contraseña",
    USERS: "Usuarios",
    ACCESS_KEY: "Clave de acceso"
  },
  APP: {
    TITLE: "Aplicación",
    TITLE_PLURAL: "Aplicaciones",
    ENTITY: "aplicación",
    TABLE_TITLE: "Listado de aplicaciones",
    TABLE_SUBTITLE: "Aplicaciones encontradas en el sistema",
    CREATE: "Crear aplicación",
    NEW: "Nueva Aplicación",
    NEW_SUB: "Ingresa una nueva aplicación",
    EDIT: "Editar aplicación",
    EDIT_SUB: "Modifica la aplicación"
  },
  SERVICE: {
    TITLE: "Servicio",
    TITLE_PLURAL: "Servicios",
    CREDITS: "créditos",
    COST: "Costo",
    DISPONIBILITY: "Disponibilidad de servicios",
    GENERAL: "Información General",
    CHARTS: "Gráficas de uso",
    MODEL_TYPE: "Tipo de modelo",
    INDUSTRY: "Industrias",
    LANG: "Idiomas",
    DEMO: "Demo",
    DEMO_INST: "Introduce un texto",
    DOCS: "Documentación",
    NO_DOC: "Por el momento, no contamos con documentación para este servicio",
    REQ_HIST: "Historial de peticiones",
    ANALIZE: "Analizar Texto",
    CHARS: "caracteres",
    PRETTY: "Pretty",
    JSON: "JSON",
    HOW_TO: "¿Cómo interpretarlo?",
    RESULT: "Resultado",
    NO_RESULT: "Sin resultados",
    NEGATIVE: "Negativo",
    NEUTRAL: "Neutral",
    POSITIVE: "Positivo",
    EXTRACTOR: "Extractor de entidades",
    SUMMARY: "Resumidor de textos",
    FIND_KEYWORDS: "Palabras clave encontradas",
    FIND_WORDS: "Textos extraidos",
    RISK_TYPE: "Tipo de riesgo",
    ACCORDANCE: "Conformidad",
    FINANCIAL: "Financiero",
    OPERATIONAL: "Operacional",
    SENTIMENT: "Sentimiento",
    NO_TEST: "Aún no has hecho ninguna prueba",
    SENTIMENT_TYPE: "Analisis de Sentimiento",
    CLASSIFIER_TYPE: "Clasificador",
    KEYWORD_TYPE: "Extractor de palabras clave",
    EXTRACTOR_TYPE: "Resumidor de textos",
    SUMMARY_TYPE: "Resumidor de textos",
    MULTIMODEL_TYPE: "Multi-modelo"
  },
  CHART: {
    TITLE: "Estadísticas"
  },
  MSG: {
    PASS_UPDATED: "La contraseña ha sido actualizada exitosamente!",
    APP_UPDATED: "La aplicación ha sido guardado exitosamente!",
    IMG_NOT_UPLOAD: {
      TITLE: "La imagen no ha sido subida",
      TEXT: "Por favor vuelve a intentarlo más tarde"
    },
    APP_NOT_CREATED: "La aplicación no ha podido ser creada",
    REQ_LIMIT_1: {
      TITLE: "Ha excedido un limite en la petición",
      TEXT: "Ha excedido el limite de palabras en la petición. ({msg})"
    },
    REQ_LIMIT_2: {
      TITLE: "Ha excedido un limite en la petición",
      TEXT: "Ha excedido el limite de caracteres en la petición. ({msg})"
    },
    CRED_INSUF: {
      TITLE: "Creditos insuficientes",
      TEXT: "No tiene los creditos suficientes para procesar esta petición"
    },
    INCORRECT_PASS: "La contraseña actual ingresada es incorrecta!",
    ALL_REQUIRED: "Todos los campos son obligatorios",
    REQUIRED: "Este campo es obligatorio",
    MIN_LEN: "El campo debe ser de al menos {len} caracteres.",
    SAME_PASS: "Las contraseñas deben ser idénticas."
  },

  FORM: {
    SEARCH: "Busqueda",
    EMAIL: "Correo electrónico",
    PASSWORD: "Contraseña",
    NAME: "Nombre",
    DESC: "Descripción",
    API_KEY: "Api Key",
    API_SECRET: "Api Secret",
    STATUS: "Estado"
  },
  AUTH: {
    GENERAL: {
      SUBTITLE_LINE1: "Desarrolla la mejor",
      SUBTITLE_LINE2: "versión de tu empresa",
      OR: "O",
      SUBMIT_BUTTON: "Enviar",
      NO_ACCOUNT: "No tienes una cuenta?",
      SIGNUP_BUTTON: "Regístrate",
      FORGOT_BUTTON: "Se te olvidó tu contraseña",
      BACK_BUTTON: "Espalda",
      PRIVACY: "Intimidad",
      LEGAL: "Legal",
      CONTACT: "Contacto"
    },
    LOGIN: {
      WELCOME: "Bienvenido a Lexic.ai",
      NEW: "¿Eres nuevo?",
      REGISTER: "Solicita una cuenta",
      FORGOT_ERROR:
        "Email y/o contraseña inválidos, si olvidaste tu contraseña da clic en: Olvidé mi contraseña",
      TITLE: "Crear una cuenta",
      BUTTON: "Iniciar Sesión"
    },
    FORGOT: {
      TITLE: "¿Olvidaste tu contraseña?",
      DESC:
        "Ingresa tu correo electronico, te enviaremos un correo de recuperación.",
      SUCCESS: "Your account has been successfully reset.",
      RETURN: "Volver",
      RECOVER: "Recuperar"
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Usuario"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  },
  NOTIFICATIONS: {
    FORMS: {
      SECTIONS: {
        CONTENT: "Contenido",
        SHOW_FROM: "Fecha de notificación",
        SEGMENTATION: "Segmentación",
        PREVIEW: "Vista Previa",
      },
      NEW_NOTIFICATION: "Nueva Notificación",
      NEW_DESCRIPTION: "Crear una nueva notificación",
      ICON: "Ícono",
      TITLE: "Título",
      BODY: "Cuerpo",
      NOW: 'Inmediatamente',
      FUTURE: 'Seleccionar fecha y hora',
      URL: {
        LABEL: "Url",
        HELP: "Puedes introducir una url absoluta o relativa. Deben empezar con https:// o /#/ respectivamente."
      },
      CREATED_AT: "Fecha de creación",
      SHOW_TO_ALL: {
        ALL: "Notificar a todos los clientes",
        SELECT: "Seleccionar clientes a notificar"
      },
      TARGET: {
        CLIENTS_SEARCH: "Buscar clientes",
        SELECTED_CLIENTS: "Clientes seleccionados"
      },
    },
    NEW: 'Nuevo',
    NOW: 'hace unos segundos'
    
  }
};
