import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "root",
      component: () => import("@/view/layout/Layout"),
      meta: {
        redirectsByPermissions: [
          {
            to: "/services",
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_AI_PRODUCT"
            ]
          },
          {
            to: "/clients",
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_CLIENTS"
            ]
          },
          {
            to: "/users",
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_USERS"
            ]
          },
          {
            to: "/notifications",
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_TENANT_MESSAGES"
            ]
          },
          {
            to: "/validations",
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_REQUEST_VALIDATIONS"
            ]
          }
          
          
        ]
      },
      children: [
        {
          path: "/clients",
          name: "clients",
          component: () => import("@/view/pages/clients/Clients.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_TENANTS"
            ]
          }
        },
        {
          path: "/clients/new",
          name: "new-client",
          component: () => import("@/view/pages/clients/NewClient.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_TENANTS"
            ]
          }
        },
        {
          path: "/clients/:id",
          name: "edit-client",
          component: () => import("@/view/pages/clients/EditClient.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_TENANTS"
            ]
          }
        },
        {
          path: "/clients/dash/:id",
          name: "dashboard-client",
          component: () => import("@/view/pages/clients/DashboardClient.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_TENANTS"
            ]
          }
        },
        {
          path: "/services",
          name: "services",
          component: () => import("@/view/pages/services/Services.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_AI_PRODUCT"
            ]
          }
        },
        {
          path: "/services/new",
          name: "new-service",
          component: () => import("@/view/pages/services/NewService.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_AI_PRODUCT"
            ]
          }
        },
        {
          path: "/services/:id",
          name: "edit-service",
          component: () => import("@/view/pages/services/EditService.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_AI_PRODUCT"
            ]
          }
        },
        {
          path: "/users/new",
          name: "new-user",
          component: () => import("@/view/pages/users/NewUser.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_USERS"
            ]
          }
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/pages/users/Users.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_USERS"
            ]
          }
        },
        {
          path: "/users/:id",
          name: "edit-user",
          component: () => import("@/view/pages/users/NewUser.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_USERS"
            ]
          }
        },
        {
          path: "/notifications",
          name: "notifications",
          component: () => import("@/view/pages/notifications/Notifications.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_TENANT_MESSAGES"
            ]
          }
        },
        {
          path: "/notifications/new",
          name: "new-notifications",
          component: () => import("@/view/pages/notifications/NewNotification.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_TENANT_MESSAGES"
            ]
          }
        },
        {
          path: "/notifications/:id",
          name: "edit-notifications",
          component: () => import("@/view/pages/notifications/NewNotification.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_TENANT_MESSAGES",
              "ADMIN_REQUEST_VALIDATIONS"
            ]
          }
        },
        {
          path: "/validations",
          name: "validations",
          component: () => import("@/view/pages/validations/Validations.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_REQUEST_VALIDATIONS"
            ]
          }
        },
        {
          path: "/validations/:id",
          name: "validate-document",
          component: () => import("@/view/pages/validations/ValidateDocument.vue"),
          meta: { 
            permissions: [
              "ADMIN_GLOBAL",
              "ADMIN_REQUEST_VALIDATIONS"
            ]
          }
        },
        // {
        //   path: "/profile",
        //   name: "profile",
        //   component: () => import("@/view/pages/Profile.vue"),
        // },
        {
          path: "/change-password",
          name: "change-password",
          component: () => import("@/view/pages/users/ChangePassword.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      name: "recovery",
      path: "/recovery/:token",
      component: () => import("@/view/pages/auth/Recovery")
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-404.vue")
    },
    {
      // the 404 route, when none of the above matches
      path: "/403",
      name: "403",
      component: () => import("@/view/pages/error/Error-403.vue")
    }
  ]
});
